<template>
    <div>
        <v-container fluid class="px-0">
            <v-card outlined elevation="3">
                <v-card-text>
                    <v-data-table
                        class="black--text"
                        :headers="headers"
                        :search="search"
                        :items="dataStock"
                        :loading="loading"
                        :items-per-page="itemsPerPage"
                        :page.sync="page"
                        hide-default-footer
                        dense
                    >
                        <template v-slot:top>
                            <v-toolbar flat dense class="mb-5">
                                <v-row dense>
                                    <v-col class="pa-0 mr-2">
                                        <v-select
                                            v-model="tujuanGudang"
                                            label="Gudang"
                                            dense
                                            outlined
                                            hide-details
                                            :items="listGudangTujuan"
                                            item-value="id"
                                            item-text="name"
                                            v-on:change="getDatas"
                                        ></v-select>
                                    </v-col>
                                    <v-col class="pa-0">
                                        <download-csv
                                            :data="dataJsonToCsv"
                                            name="Laporan Stock.csv"
                                        >
                                            <v-btn
                                                color="#61B15A"
                                                dark
                                                class="mb-2 text-subtitle-1"
                                                style="text-transform: unset !important"
                                            >
                                                <v-icon left
                                                    >mdi-file-download</v-icon
                                                >
                                                Unduh Data
                                            </v-btn>
                                        </download-csv>
                                    </v-col>
                                </v-row>
                            </v-toolbar>
                            <v-toolbar flat dense class="mb-5">
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    autocomple="off"
                                    placeholder="Search..."
                                ></v-text-field>
                            </v-toolbar>
                            <div
                                class="d-flex mb-5 px-4 justify-space-between align-center"
                            >
                                <v-row>
                                    <v-col cols="2">
                                        <v-select
                                            v-model="itemsPerPage"
                                            :items="showPerPage"
                                            outlined
                                            dense
                                            label="items per page"
                                            v-on:change="changeItemPerPage"
                                        >
                                        </v-select>
                                    </v-col>
                                    <v-col align="right">
                                        <span class="mr-15 text-caption"
                                            >Page: {{ page }}</span
                                        >
                                        <v-btn
                                            fab
                                            x-small
                                            depressed
                                            color="primary"
                                            class="mx-3"
                                            @click="prevPage"
                                        >
                                            <v-icon dark>
                                                mdi-chevron-left
                                            </v-icon>
                                        </v-btn>
                                        <v-btn
                                            fab
                                            x-small
                                            depressed
                                            color="primary"
                                            class="mx-3"
                                            @click="nextPage"
                                        >
                                            <v-icon dark>
                                                mdi-chevron-right
                                            </v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </template>

                        <template v-slot:item.qty="{ item }">
                            <span>{{ getQtyStock(item) }}</span>
                        </template>
                    </v-data-table>
                    <div
                        class="d-flex mt-5 px-4 justify-space-between align-center"
                    >
                        <v-row>
                            <v-col cols="2">
                                <v-select
                                    v-model="itemsPerPage"
                                    :items="showPerPage"
                                    outlined
                                    dense
                                    label="items per page"
                                    v-on:change="changeItemPerPage"
                                >
                                </v-select>
                            </v-col>
                            <v-col align="right">
                                <span class="mr-15 text-caption"
                                    >Page: {{ page }}</span
                                >
                                <v-btn
                                    fab
                                    x-small
                                    depressed
                                    color="primary"
                                    class="mx-3"
                                    @click="prevPage"
                                >
                                    <v-icon dark> mdi-chevron-left </v-icon>
                                </v-btn>
                                <v-btn
                                    fab
                                    x-small
                                    depressed
                                    color="primary"
                                    class="mx-3"
                                    @click="nextPage"
                                >
                                    <v-icon dark> mdi-chevron-right </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";

export default {
    data: () => ({
        search: "",
        loading: false,
        listGudangTujuan: [],
        headers: [
            {
                text: "Gudang",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "gudang_name",
            },
            {
                text: "Material",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "material_code",
            },
            {
                text: "Batch",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "batch_no",
            },
            {
                text: "Satuan",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "satuan",
            },
            {
                text: "Quantity",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "qty",
            },
        ],
        dataStock: [],
        tujuanGudang: "",
        dataJsonToCsv: [],
        page: 1,
        itemsPerPage: 10,
        showPerPage: [5, 10, 20, 50, 100, 250, 500],
    }),

    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },

    methods: {
        ...mapActions({
            refreshAction: "auth/refresh",
        }),

        applyFilter() {
            this.loading = true;

            axios
                .get(
                    "v1/admin/laporan/gudang/stock?limit=" +
                        this.itemsPerPage +
                        "&page=" +
                        this.page +
                        "&gudang_id=" +
                        this.tujuanGudang
                )
                .then((response) => {
                    this.loading = false;
                    this.dataStock = response.data.data;
                    this.unduhData();
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            this.refreshAction(localStorage.getItem("refresh"));
                            this.applyFilter();
                        }
                    }
                });
        },
        getDatas() {
            //   this.getLoadingDate();
            this.applyFilter();
        },
        getGudang() {
            this.getGudangTujuan();
            //   this.getGudangAsal();
        },
        getGudangTujuan() {
            const defaultSelect = { id: "", name: "semua gudang" };
            this.listGudangTujuan.push(defaultSelect);
            axios.get("v1/user/gudang/list?limit=100").then((response) => {
                response.data.data.forEach((v) => {
                    this.listGudangTujuan.push(v);
                });
            });
        },
        // getGudangAsal() {
        //   const defaultSelect = { id: "", name: "semua gudang" };
        //   this.listAsalGudang.push(defaultSelect);
        //   this.user.listGudang.forEach((v) => {
        //     axios.get("v1/user/gudang?id=" + v).then((response) => {
        //       if (response.data.data.is_activated === 1) {
        //         this.listAsalGudang.push(response.data.data);
        //       }
        //       this.applyFilter();
        //     });
        //   });
        // },

        changeItemPerPage() {
            this.applyFilter();
        },
        prevPage() {
            if (this.page === 1) {
                return;
            } else {
                this.page = this.page - 1;
                this.applyFilter();
            }
        },
        nextPage() {
            if (this.dataBarcode === null) {
                return;
            } else {
                this.page = this.page + 1;
                this.applyFilter();
            }
        },
        next(page) {
            this.page = page;
            this.applyFilter();
        },

        unduhData() {
            axios
                .get(
                    "v1/admin/laporan/gudang/stock?limit=" +
                        this.itemsPerPage +
                        "&page=" +
                        this.page +
                        "&gudang_id=" +
                        this.tujuanGudang +
                        "&download=1"
                )
                .then((response) => {
                    var parseData = this.$papa.parse(response.data, {
                        header: true,
                    });
                    this.dataJsonToCsv = parseData.data;
                });
        },
    },
    created() {
        this.getGudang();
        this.getDatas();
        // this.getLoadingDate();
    },
};
</script>
