<template>
    <div>
        <v-container fluid class="px-0">
            <v-card outlined elevation="3">
                <v-card-title class="py-0 px-3 mt-5 mb-0">
                    <v-toolbar flat dense class="mb-5">
                        <v-row dense justify="start">
                            <v-col class="pa-0 mr-2 d-none">
                                <v-menu
                                    v-model="loadingDateMenu"
                                    :close-on-content-click="false"
                                    :nudge-left="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="loadingDate"
                                            append-icon="mdi-calendar-month"
                                            label="Loading Date"
                                            readonly
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                            outlined
                                            hide-details
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        ref="picker"
                                        v-model="loadingDate"
                                        range
                                        v-on:change="applyFilter"
                                    >
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="pa-0 mr-2">
                                <v-select
                                    v-model="asalGudang"
                                    label="Gudang Asal"
                                    dense
                                    outlined
                                    hide-details
                                    :items="listAsalGudang"
                                    item-value="id"
                                    item-text="name"
                                    v-on:change="getDatas"
                                ></v-select>
                            </v-col>
                            <v-col class="pa-0 mr-2">
                                <v-select
                                    v-model="tujuanGudang"
                                    label="Gudang Tujuan"
                                    dense
                                    outlined
                                    hide-details
                                    :items="listGudangTujuan"
                                    item-value="id"
                                    item-text="name"
                                    v-on:change="getDatas"
                                ></v-select>
                            </v-col>
                            <v-col class="pa-0 mr-2">
                                <v-select
                                    v-model="material"
                                    placeholder="material"
                                    dense
                                    outlined
                                    single-line
                                    hide-details
                                    :items="listMaterial"
                                    item-value="id"
                                    item-text="name"
                                    v-on:change="getDatas"
                                ></v-select>
                            </v-col>
                            <v-col class="pa-0 mr-2">
                                <v-select
                                    v-model="loadingDates"
                                    label="Loading Date"
                                    dense
                                    outlined
                                    hide-details
                                    :items="listloadingDate"
                                    item-value="created_at"
                                    v-on:change="applyFilter"
                                >
                                    <template
                                        slot="selection"
                                        slot-scope="data"
                                    >
                                        <span v-if="data.item.created_at == ''"
                                            >all</span
                                        >
                                        <span v-else>{{
                                            data.item.created_at.substring(
                                                0,
                                                10
                                            )
                                        }}</span>
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        <span v-if="data.item.created_at == ''"
                                            >all</span
                                        >
                                        <span v-else>{{
                                            data.item.created_at.substring(
                                                0,
                                                10
                                            )
                                        }}</span>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col class="pa-0">
                                <download-csv
                                    :data="dataJsonToCsv"
                                    name="Laporan In Transit.csv"
                                >
                                    <v-btn
                                        color="#61B15A"
                                        dark
                                        class="mb-2 text-subtitle-1"
                                        style="text-transform: unset !important"
                                    >
                                        <v-icon left>mdi-file-download</v-icon>
                                        Unduh Data
                                    </v-btn>
                                </download-csv>
                            </v-col>
                        </v-row>
                    </v-toolbar>
                </v-card-title>

                <v-card-text>
                    <v-data-table
                        class="black--text"
                        :headers="headers"
                        :search="search"
                        :items="dataInTransit"
                        :loading="loading"
                        dense
                    >
                        <template v-slot:top>
                            <v-text-field
                                outlined
                                dense
                                v-model="search"
                                append-icon="mdi-magnify"
                                autocomple="off"
                                placeholder="Search..."
                            ></v-text-field>
                        </template>
                        <template v-slot:item.total="{ item }">
                            <span>
                                {{ getQtyStock(item) }}
                            </span>
                        </template>
                        <template v-slot:item.created_at="{ item }">
                            {{ item.created_at | moment("YYYY/MM/DD") }}
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";

export default {
    data: () => ({
        search: "",
        loading: false,
        expiredDateMenu: false,
        expiredDate: "",
        manufactureDateMenu: false,
        manufacturingDate: "",
        loadingDateMenu: false,
        loadingDate: [],
        loadingDates: "",
        listloadingDate: [],
        listGudangTujuan: [],
        listAsalGudang: [],
        listMaterial: [
            { id: "", name: "semua material" },
            { id: "MC", name: "MC" },
            { id: "IC", name: "IC" },
            { id: "BTL", name: "BTL" },
        ],
        headers: [
            {
                text: "Gudang Pengirim",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "gudang_asal_name",
            },
            {
                text: "Gudang Tujuan",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "gudang_penerima_name",
            },
            {
                text: "Material",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "material_code",
            },
            {
                text: "Batch",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "batch_no",
            },
            {
                text: "Qty",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "total",
            },
            {
                text: "Satuan",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "satuan",
            },
            {
                text: "Surat Jalan",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "nomer_seri",
            },
            {
                text: "Created Date",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "created_at",
            },
        ],
        dataInTransit: [],
        tujuanGudang: "",
        asalGudang: "",
        material: "",
        dataJsonToCsv: [],
        page: 1,
        itemsPerPage: 10,
        showPerPage: [5, 10, 20, 50, 100, 250, 500],
    }),

    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },

    methods: {
        ...mapActions({
            refreshAction: "auth/refresh",
        }),

        getData() {
            this.loading = true;
            axios
                .get(
                    "v1/admin/laporan/flow/transisi/list?limit=" +
                        this.itemsPerPage +
                        "&page=" +
                        this.page
                )
                .then((response) => {
                    this.loading = false;
                    this.dataInTransit = response.data.data;
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            this.refreshAction(localStorage.getItem("refresh"));
                            this.getData();
                        }
                        console.clear();
                    }
                });
        },
        applyFilter() {
            this.loading = true;
            // if (this.loadingDate.length !== 0) {
            //   if (this.loadingDate[0] > this.loadingDate[1]) {
            //     this.created_at_from = this.loadingDate[1] + "T00:00:00.000Z";
            //     this.created_at_to = this.loadingDate[0] + "T23:59:59.000Z";
            //   } else {
            //     this.created_at_from = this.loadingDate[0] + "T00:00:00.000Z";
            //     this.created_at_to = this.loadingDate[1] + "T23:59:59.000Z";
            //   }
            // }
            if (this.loadingDates !== "") {
                this.created_at_from =
                    this.loadingDates.substring(0, 10) + "T00:00:00.000Z";
                this.created_at_to =
                    this.loadingDates.substring(0, 10) + "T23:59:59.000Z";
            }
            axios
                .get(
                    "v1/admin/laporan/flow/transisi/list?limit=" +
                        this.itemsPerPage +
                        "&page=" +
                        this.page +
                        "&gudang_asal_id=" +
                        this.asalGudang +
                        "&gudang_penerima_id=" +
                        this.tujuanGudang +
                        "&material=" +
                        this.material
                )
                .then((response) => {
                    this.loading = false;
                    this.dataInTransit = response.data.data;
                    this.unduhData();
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            this.refreshAction(localStorage.getItem("refresh"));
                            this.applyFilter();
                        }
                    }
                });
        },
        getDatas() {
            this.getLoadingDate();
            this.applyFilter();
        },
        getGudang() {
            this.getGudangTujuan();
            this.getGudangAsal();
        },
        getGudangTujuan() {
            const defaultSelect = { id: "", name: "semua gudang" };
            this.listGudangTujuan.push(defaultSelect);
            axios.get("v1/user/gudang/list?limit=100").then((response) => {
                response.data.data.forEach((v) => {
                    this.listGudangTujuan.push(v);
                });
            });
        },
        getGudangAsal() {
            const defaultSelect = { id: "", name: "semua gudang" };
            this.listAsalGudang.push(defaultSelect);
            this.user.listGudang.forEach((v) => {
                axios.get("v1/user/gudang?id=" + v).then((response) => {
                    if (response.data.data.is_activated === 1) {
                        this.listAsalGudang.push(response.data.data);
                    }
                    this.applyFilter();
                });
            });
        },
        async getLoadingDate() {
            const defaultSelect = { created_at: "" };
            axios
                .get(
                    "v1/admin/laporan/flow/transisi/getLoadDate?gudang_asal_id=" +
                        this.asalGudang +
                        "&gudang_penerima_id=" +
                        this.tujuanGudang +
                        "&material=" +
                        this.material
                )
                .then((response) => {
                    this.listloadingDate = response.data.data;
                    this.listloadingDate.push(defaultSelect);
                });
        },
        changeItemPerPage() {
            this.applyFilter();
        },
        prevPage() {
            if (this.page === 1) {
                return;
            } else {
                this.page = this.page - 1;
                this.applyFilter();
            }
        },
        nextPage() {
            if (this.dataBarcode === null) {
                return;
            } else {
                this.page = this.page + 1;
                this.applyFilter();
            }
        },
        next(page) {
            this.page = page;
            this.applyFilter();
        },
        unduhData() {
            axios
                .get(
                    "v1/admin/laporan/flow/transisi/list?limit=" +
                        this.itemsPerPage +
                        "&page=" +
                        this.page +
                        "&gudang_asal_id=" +
                        this.asalGudang +
                        "&gudang_penerima_id=" +
                        this.tujuanGudang +
                        "&material=" +
                        this.material +
                        "&download=1"
                )
                .then((response) => {
                    var parseData = this.$papa.parse(response.data, {
                        header: true,
                    });
                    this.dataJsonToCsv = parseData.data;
                });
        },
    },

    created() {
        this.getGudang();
        this.getLoadingDate();
    },
};
</script>
